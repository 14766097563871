import breadcrumbComp from "../../../src/components/common/breadcrumb-comp.vue";

import btoService from "./bto-service";
import EncryptUtility from "../../components/utility/js/encrypt-utility";
import Utility from "../../shared/utility";
import AddFavourite from "../../components/utility/js/addFav";
import BackToTop from "../../../src/components/common/back-to-top.vue";
import ReadOnlyDialog from "../../../src/components/common/read-only-dialog.vue";
import DisplayTextData from "@/components/common/display-text-data.vue";
import PrinterDetails from "@/components/common/printer-details.vue";
import commonApiService from "@/components/common/js/common-api-service";

export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      route: "",
      secondColSize: 6,
      actionList: [],
      mainPage: [],
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      subPageUrl: "",
      appPageName: "",
      line_key: "",
      backSlashParent: true,
      backSlash: true,
      showAdd: true,
      showAddKeyDialog: false,
      showDetails: false,
      commonDialog: false,
      tableItems: [],
      addKeyList: [],
      soId: "",
      sono: "",
      btoSearch: "",
      validateUnit: "",
      required: [(v) => !!v || "Field is required"],
      searchLPNRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) =>
          /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) ||
          "Enter a correct format: 000-000-000-000",
      ],
      btoDetailsArray: [],
      configureDetailsArray: [],
      assemblyData: [],
      configNotes: "",
      stagingLocation: "",
      bonoId: "",
      orderComments: "",
      deviceName: "",
      btoNotes: "",
      workStationName: "",
      licenceText: "",
      licenseDialog: false,
      formValid: false,
      activeTab: 0,
      tabs: [],
      validateSection: false,
      btoDetailsDataList: [],
      loadListData: [],
      lpn: "",
      isDOA: false,
      editLicenceKey: false,
      primaryLabelSize: EncryptUtility.localStorageDecrypt("primaryLabelSize"),
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      showPrimary: true,
      printDisable: true,
      printerConfigured: false,
      temp_id: "",
      deviceDisable: true,
      itemIsLoaded: false,
      loadListHeader: [
        { text: "", value: "showKeys", class: "primary customwhite--text" },
        {
          text: "Software Description",
          value: "part_num",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "qty",
          class: "primary customwhite--text",
        },
        {
          text: "Installed",
          value: "isloaded",
          class: "primary customwhite--text",
        },
        {
          text: "Load Note",
          value: "load_note",
          class: "primary customwhite--text",
        },
      ],
      assemblyListHeader: [
        { text: "", value: "showKeys", class: "primary customwhite--text" },
        {
          text: "Part Number",
          value: "part_num",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "qty",
          class: "primary customwhite--text",
        },
        {
          text: "Issued Units",
          value: "serialno",
          class: "primary customwhite--text",
        },

        {
          text: "Assembly Notes",
          value: "build_note",
          class: "primary customwhite--text",
        },
      ],
      loadListKeyHeader: [
        {
          text: "License Key",
          value: "license_key",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "cdate",
          class: "primary customwhite--text",
        },
        {
          text: "User",
          value: "key_user",
          class: "primary customwhite--text",
        },
      ],
      commonHeader: [],
      addKeyHeader: [
        {
          text: "License Key",
          value: "license_key",
          class: "primary customwhite--text",
        },
        { text: "Action", value: "action", class: "primary customwhite--text" },
      ],
      unAssignHeader: [
        { text: "Kit", value: "Kit", class: "primary customwhite--text" },
        {
          text: "Created",
          value: "Created",
          class: "primary customwhite--text",
        },
        {
          text: "Unassigned",
          value: "Unassigned",
          class: "primary customwhite--text",
        },
        {
          text: "Unassigned By",
          value: "Unassign_by",
          class: "primary customwhite--text",
        },
        {
          text: "Action ",
          value: "Action",
          class: "primary customwhite--text",
        },
        { text: "Part", value: "Part", class: "primary customwhite--text" },
        { text: "Asset", value: "Asset", class: "primary customwhite--text" },
        {
          text: "Latest Location",
          value: "Latest_Location",
          class: "primary customwhite--text",
        },
        {
          text: "Unit Status",
          value: "Unit_Status",
          class: "primary customwhite--text",
        },
      ],
    };
  },

  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    //to Load
    this.bonoId = atob(this.$route.params.id);
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data?.actionList;
    let actionData = await AddFavourite.getParentPageAction(
      this.userId,
      "bto-details"
    ); // URL hard coded
    let pagePermissions = actionData?.actionList;
    pagePermissions?.forEach((element) => {
      this.actionList.push(element);
    });
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    this.gridData("id");
  },

  computed: {
    sortedTabs() {
      return this.tabs.sort((a, b) => a.sequence - b.sequence);
    },
    lastUpdateUser() {
      return this.btoDetailsDataList?.GridData[0]?.config_euser || null;
    },
    lastCompletedUser() {
      return this.btoDetailsDataList?.GridData[0]?.config_user || null;
    },
    lastUpdateDate() {
      return this.btoDetailsDataList?.GridData[0]?.config_edate || null;
    },
    lastCompletedDate() {
      return this.btoDetailsDataList?.GridData[0]?.config_date || null;
    },
  },
  async mounted() {},
  methods: {
    // to display bold in table data
    boldNote(text) {
      // Wrap "BCN" with a <strong> tag for bold styling
      return text.replace(/\b(BCN|SN)\b/g, "<b>$1</b>");
    },

    // let us know which tab to return
    shouldDisableTab(obj) {
      if (obj.list_id === 0) {
        return true;
      }
    },
    // to popluate grid data
    async gridData(loc) {
      this.stagingLocation = "";
      this.lpn = "";
      let detailsObj = {
        bono: loc == "search" ? this.btoSearch : this.bonoId,
        user_key: this.userId,
        menu_id: 0,
        requst_kit_id: 0,
      };
      let btoDetailsData = await btoService.getBtoDetails(
        "post",
        detailsObj,
        false
      );
      btoDetailsData
        ? this.mappingHeaders(btoDetailsData)
        : this.redirectToListing();
    },

    //lpn num format
    lpnNumFormat(event, num) {
      this.lpn = Utility.formatLPNNumber(event, num);
    },
    // on Click of Submit device
    async onClickSubmitDevice() {
      let nameObj = {
        userid: this.userId,
        so_line_key: this.btoDetailsDataList?.GridData[0]?.so_line_key,
        newdevicename: this.deviceName,
      };

      let responseData = await btoService.postBtoDeviceChange(
        "post",
        nameObj,
        true
      );
      let res = JSON.parse(responseData);
      res?.Status == 200
        ? (this.deviceDisable = true)
        : (this.deviceDisable = false);
    },

    //get lpn number
    async getLPNNumber() {
      let lpnData = await commonApiService.getNextAvailableLPN(
        "get",
        this.userId
      );
      if (lpnData.message !== "NA") {
        this.lpn = lpnData[0]?.lpn;
      }
    },
    async onClickSave() {
      if (this.onBlurStagingLoctaion() || this.onBlurLPN()) {
        const formRef = this.$refs.workStationForm[0];
        if (formRef.validate() && this.$refs.configForm[0].validate()) {
          let saveObj = {
            user_id: this.userId,
            kit_id: this.btoDetailsDataList?.GridData[0].kit_id,
            notes: this.configNotes,
            mac_addr: this.btoDetailsDataList?.GridData[0].mac_addr,
            wifi_mac_addr: this.btoDetailsDataList?.GridData[0].wifi_mac_addr,
            bt_mac_addr: this.btoDetailsDataList?.GridData[0].mac_addr,
            cell_mac_addr: this.btoDetailsDataList?.GridData[0].mac_addr,
            token_key: this.btoDetailsDataList?.GridData[0].token_key,
            imei: this.btoDetailsDataList?.GridData[0].imei,
            iccid: this.btoDetailsDataList?.GridData[0].iccid,
            menu_id: 0,
            ElavonWSName: this.workStationName,
            DOA: this.doa ? 1 : 0,
          };
          let responseData = await btoService.postBtoSave("post", saveObj);

          let resp = JSON.parse(responseData);
          this.bonoId = atob(this.$route.params.id);
          resp?.Status == 200 ? this.gridData("id") : "";
        }
      }
    },
    onClickSearch() {
      if (this.$refs.searchForm.validate()) this.gridData("search");
    },
    statusCss(statusData) {
      if (statusData == "C") return "activeCheck pa-1";
      else if (statusData == "X") return "inactive pa-1";
      else return "secondary-chip pa-1";
    },
    mappingHeaders(btoDetailsData) {
      this.showDetails = true;
      this.btoDetailsDataList = btoDetailsData;
      const gridData = btoDetailsData?.GridData[0] || {};
      const projCode = gridData?.proj_code;
      this.validateSection = "main_key" in gridData && gridData?.main_key === 0;
      this.soId = gridData.so_id;
      this.sono = gridData?.serno;
      // Base Details Array
      this.btoDetailsArray = [
        {
          label: "Status:",
          value: gridData?.status_descrip,
          valueCss: this.statusCss(gridData?.status),
        },
        { label: "Order:", value: gridData?.serno, valueCss: "f-16" },
        { label: "BTO:", value: gridData?.bono, valueCss: "f-16" },
        {
          label:
            gridData?.processtype === "k"
              ? "Kitted Part Number"
              : "Base-Assembly:",
          value: gridData?.part_num,
          subValue: gridData?.part_desc,
        },
        {
          label:
            gridData?.processtype === "k"
              ? "Unit Assigned To This Kit"
              : "Unit Assigned To This Build:",
          value: this.getUnitDescription(gridData),
          valueCss:
            this.getUnitDescription(gridData) == "No Unit Assigned"
              ? "f-16 f-bold error--text"
              : "",
        },
      ];

      // Add USB-specific fields and column size adjustment
      if (projCode === "USB") {
        this.secondColSize = 6;
        this.btoDetailsArray.push(
          { label: "Request Number:", value: gridData?.RequestNumber },
          { label: "RITM:", value: gridData?.RITM },
          { label: "Task ID:", value: gridData?.TaskID }
        );
      } else {
        this.secondColSize = 12;
      }
      // Add Employee details if present
      if (gridData?.emp_id || gridData?.emp_user) {
        this.btoDetailsArray.push(
          { label: "EmployeeID:", value: gridData?.emp_id },
          { label: "Employee:", value: gridData?.emp_user }
        );
      }
      this.workStationName = gridData?.USB_ElavonWSName;
      // Configuration Details Array
      this.configureDetailsArray = [
        { label: "ESN / IMEI:", value: gridData?.imei },
        { label: "ICCID:", value: gridData?.iccid },
        { label: "Device MAC Address:", value: gridData?.mac_addr },
        {
          label: "Wireless/Ethernet MAC Address:",
          value: gridData?.wifi_mac_addr,
        },
        { label: "Bluetooth MAC Address:", value: gridData?.bt_mac_addr },
        { label: "Cellular MAC Address:", value: gridData?.cell_mac_addr },
        {
          label:
            projCode === "DAL" ? "BitLocker PIN or Recovery Key:" : "Token Key",
          value: gridData?.token_key,
        },
      ];
      // Additional Data
      this.loadListData = btoDetailsData?.Load;
      this.orderComments = gridData?.comments;
      this.btoNotes = gridData?.kit_note;
      this.deviceName = gridData?.USB_Notes_Device;
      this.doa = gridData?.USB_DOA;
      this.configNotes = gridData?.config_notes;
      this.assemblyData = btoDetailsData?.Assembly;
      this.tabs = btoDetailsData?.DisplaySequence || [];

      let displayString = "LOAD"; // Default value
      // Made an adjustment if status is "c" then move active tab to assembly in uat of legacy its in config
      if (gridData?.status === "B" || gridData?.status === "C") {
        displayString = "ASSEMBLE";
      } else if (gridData?.status === "N") {
        displayString = "CONFIGURE";
      }
      const filterTab = this.tabs.find(
        (tab) => tab.displaystring === displayString
      );
      console.log(397, filterTab);
      this.activeTab = filterTab ? filterTab.sequence - 1 : -1; // Fallback to -1 if no matching tab is found
      console.log(399, this.activeTab);
      const requiredTabs = [
        { displaystring: "LOAD", sequence: 1 },
        { displaystring: "CONFIGURE", sequence: 2 },
        { displaystring: "ASSEMBLE", sequence: 3 },
      ];

      // Function to check and add missing tabs
      function ensureAllTabs(tabs) {
        const existingTabNames = tabs.map((tab) => tab.displaystring);

        // Loop through requiredTabs and add missing ones
        requiredTabs.forEach((requiredTab) => {
          if (!existingTabNames.includes(requiredTab.displaystring)) {
            tabs.push({ ...requiredTab, list_id: 0 });
          }
        });

        // Sort the tabs by sequence to maintain order
        tabs.sort((a, b) => a.sequence - b.sequence);

        return tabs;
      }
      // Update the tabs
      this.tabs = ensureAllTabs(this.tabs);

      const dateFields = [
        "load_date",
        "config_edate",
        "config_date",
        "build_date",
      ];
      {
        dateFields.forEach((field) => {
          if (field in gridData)
            gridData[field] = Utility.convertESTToLocal(gridData[field]);
        });
      }
    },
    getUnitDescription(gridData) {
      console.log(Boolean(gridData?.main_key));
      if (gridData?.main_key) {
        if (gridData?.bcn && gridData?.serialno) {
          return `BCN:${gridData?.bcn}, Serial#:${gridData?.serialno}`;
        }
        if (gridData?.bcn) return `BCN:${gridData?.bcn}`;
        if (gridData?.serialno) return `Serial#:${gridData?.serialno}`;
        if (gridData?.esn) return `ESN:${gridData?.esn}`;
      } else {
        return "No Unit Assigned";
      }
    },
    async onClickValidate() {
      let validateObj = {
        kit_id: this.btoDetailsDataList.GridData[0]?.kit_id,
        identifier: this.validateUnit,
        user_key: this.userId,
        menu_id: 0,
      };
      await btoService.postValidatdeUnit("post", validateObj, false);
    },
    async onClickLoadCompelete() {
      let loadObj = {
        kit_key: this.btoDetailsDataList.GridData[0]?.kit_id,
        user_key: this.userId,
        bin: this.stagingLocation,
        lpn: this.lpn,
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryName,
        menu_id: 111, // Hard COded Value
      };
      let responseData = await btoService.postLoadComplete(
        "post",
        loadObj,
        true
      );
      let resp = JSON.parse(responseData);
      resp?.Message[0]?.label
        ? PrinterDetails.printLabelDataArray(
            resp?.Message[0]?.label,
            this.primaryName
          )
        : "";
      this.bonoId = atob(this.$route.params.id);
      resp?.Status == 200 ? this.gridData("id") : "";
    },
    async onClickShowKey(item) {
      let showKeyResponse = await btoService.getShowKeysTable(
        "get",
        item.line_id,
        this.btoDetailsDataList.GridData[0]?.kit_id
      );
      showKeyResponse.forEach((element) => {
        element.cdate = Utility.convertESTToLocal(element.cdate);
      });
      this.commonDialog = true;
      this.dialogHeader = "Show License Keys";
      this.tableItems = showKeyResponse;
      this.commonHeader = this.loadListKeyHeader;
    },
    closeDialog() {
      this.commonDialog = false;
    },
    async onClickAddKey(id) {
      this.addKeyList = [];
      this.showAddKeyDialog = true;
      this.line_key = id;
      let showKeyResponse = await btoService.loadAddKey(
        "get",
        id,
        this.btoDetailsDataList.GridData[0]?.kit_id
      );
      if (Array.isArray(showKeyResponse)) this.addKeyList = showKeyResponse;
      else {
        this.addKeyList = [];
      }
    },
    async onDeleteKey(item) {
      let deleteKeyObj = {
        userid: this.userId,
        templk_id: item.templk_id,
        line_key: item.line_key,
        kit_key: item.kit_key,
      };
      await btoService.loadAddKeyDelete("post", deleteKeyObj, true);
      this.addKeyList = [];
      this.onClickAddKey(item.line_key);
    },
    newAddClicked() {
      this.licenseDialog = true;
    },
    async onSaveLicenceKey() {
      let saveObj = {
        userid: this.userId,
        line_key: this.line_key,
        templk_id: 0,
        kit_key: this.btoDetailsDataList.GridData[0]?.kit_id,
        license: this.licenceText,
      };
      await btoService.postLicenceSave("post", saveObj, true);
      this.licenseDialog = false;
      this.onClickAddKey(this.line_key);
      this.licenceText = "";
    },
    async onClickConfigComplete() {
      if (
        this.$refs.workStationForm[0].validate() &&
        this.$refs.configForm[0].validate()
      ) {
        let obj = {
          userid: this.userId,
          kit_key: this.btoDetailsDataList.GridData[0].kit_id,
          notes: this.configNotes,
          mac_addr: this.btoDetailsDataList.GridData[0].mac_addr,
          wifi_mac_addr: this.btoDetailsDataList.GridData[0].wifi_mac_addr,
          bt_mac_addr: this.btoDetailsDataList.GridData[0].mac_addr,
          cell_mac_addr: this.btoDetailsDataList.GridData[0].mac_addr,
          token_key: this.btoDetailsDataList.GridData[0].token_key,
          imei: this.btoDetailsDataList.GridData[0].imei,
          iccid: this.btoDetailsDataList.GridData[0].iccid,
          bin: this.stagingLocation,
          lpn: this.lpn,
          lbl_size: this.primaryLabelSize,
          printer_type: this.primaryName,
          menu_id: 111,
          ElavonWSName: this.workStationName,
          DOA: this.doa ? 1 : 0,
        };
        let res = await btoService.postBtoConfigComplete("post", obj, false);

        res[0]?.label
          ? PrinterDetails.printLabelData(res[0]?.label, this.primaryName)
          : "";

        if (res[0].loc_out == "S01") {
          let Alert = {
            type: "success",
            isShow: true,
            message: res[0].wc_name,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.soId > 0 ? this.generatePullSheet() : "";
        }
        if (res[0].loc_out == "TBE") {
          let Alert = {
            type: "error",
            isShow: true,
            message: res[0].wc_name,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        this.redirectToListing();
      }
    },
    generatePullSheet() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let obj = {
        user_id: parseInt(this.userId),
        po_id: 0, // not needed as per this condition
        so_id: this.soId,
        purno: "",
        sono: "",
      };
      this.axios
        .post("sh/shipping_pullsheet_report", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.pdfName = "Order_Details_" + ".pdf";
            console.log(response);
            let responseData = response.data.body.message;
            //Default Printer is consumed to print the PDF
            Utility.printZPLPDF(responseData, this.defaultName);
            //           this.selectedOrders = [];
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    redirectToListing() {
      this.$router.push(`/bto-in-progress`);
    },
    async onClickUnassign() {
      let resp = await btoService.getUnassignData(
        "get",
        this.userId,
        this.btoDetailsDataList?.GridData[0]?.bono
      );
      if (resp?.length > 0) {
        this.commonDialog = true;
        this.dialogHeader = "Show Unassigned Logs";
        this.commonHeader = this.unAssignHeader;
        this.tableItems = resp;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Availble",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    async onChangeLoad(item) {
      let loadObj = this.loadListData.filter((x) => x.line_id == item.line_id);
      let obj = {
        kit_key: this.btoDetailsDataList.GridData[0].kit_id,
        user_id: this.userId,
        line_key: item.line_id,
        active: loadObj[0].isloaded ? 1 : 0,
      };
      let resp = await btoService.postBtoTempLoadSave("post", obj, false);
      let Alert = {
        type: "success",
        isShow: true,
        message: resp?.Result[0]?.Message,
      };

      this.$store.commit("ConfigModule/Alert", Alert);
      let temp_objIdx = this.loadListData.findIndex(
        (v) => v.line_id === item.line_id
      );
      if (!~temp_objIdx) {
        return;
      }
      this.loadListData.splice(temp_objIdx, 1, resp.Data[0]);
    },
    async onBlurStagingLoctaion() {
      let obj = {
        lpn: "",
        bin: this.stagingLocation,
        pass: 2,
      };
      if (this.$refs.configForm[0].validate()) {
        let res = await btoService.getBtoStagingLpnCheck("post", obj);
        return res;
      }
    },
    async onBlurLPN() {
      let obj = {
        lpn: this.lpn,
        bin: "",
        pass: 1,
      };
      let res = await btoService.getBtoStagingLpnCheck("post", obj);
      return res;
    },
    clearSearch() {
      this.showDetails = false;
    },
  },

  components: {
    breadcrumbComp,
    BackToTop,
    DisplayTextData,
    ReadOnlyDialog,
    PrinterDetails,
  },
};
